import Cookies from 'js-cookie';
import _get from 'lodash/get';
import { COOKIE_LANGUAGE_NAME } from './constant';

const setLangCodeToUserStorage = lang => {
    Cookies.set(COOKIE_LANGUAGE_NAME, lang);
};

const getTranslatedText = (transKey, params, resource) => {
    if (transKey) {
        if (!params) {
            return _get(resource, transKey) || '';
        }
        const _isObject = require('lodash/isObject');
        if (_isObject(params)) {
            const _forIn = require('lodash/forIn');
            let text = _get(resource, transKey) || '';
            if (text) {
                _forIn(params, (paramValue, paramKey) => {
                    text = text.replace(
                        new RegExp(`{{${paramKey}}}`),
                        paramValue
                    );
                });
            }
            return text;
        }
    }
    return '';
};

export { getTranslatedText, setLangCodeToUserStorage };
