import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import LeftMenuDonor from 'view/DonorLayout/LivingDonorLayout/LeftMenuDonor';
import useRecipientLayoutContext from 'view/RecipientLayout/RecipientLayoutContext';

const LivingDonorMain = memo(() => {
    // const defaultRoute = constant.ROUTE_DONOR__ADMINISTRATIVE;
    const { isHideMenu, infoData } = useRecipientLayoutContext();
    return (
        <div className="home-container">
            <LeftMenuDonor infoData={infoData} isHideMenu={isHideMenu} />
            <div
                className={`patient-main-container ${isHideMenu ? 'open' : ''}`}
            >
                <Outlet />
            </div>
        </div>
    );
});

LivingDonorMain.displayName = 'LivingDonorMain';

export default LivingDonorMain;
