import { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { themeCommon } from '../common';
import _forIn from 'lodash/forIn';
import { storeDispatcher } from '../../store/storeDispatcher';

// const regExpHex = /^#[a-fA-F0-9]{6}$|[a-fA-F0-9]{3}$/i;
const isValidHex = hex => {
    if (!hex || typeof hex !== 'string') return false;
    if (hex.substring(0, 1) === '#') hex = hex.substring(1);
    switch (hex.length) {
        case 3:
            return /^[0-9A-F]{3}$/i.test(hex);
        case 6:
            return /^[0-9A-F]{6}$/i.test(hex);
        case 8:
            return /^[0-9A-F]{8}$/i.test(hex);
        default:
            return false;
    }
};

const hexToRgb = hex => {
    return `${('0x' + hex[1] + hex[2]) | 0}, ${('0x' + hex[3] + hex[4]) | 0}, ${
        ('0x' + hex[5] + hex[6]) | 0
    }`;
};

const GlobalTheme = memo(() => {
    const { themeName } = useSelector(state => state.themeReducer);

    const applyTheme = useCallback(template => {
        if (template) {
            const { variables, components, constants } = template;
            // css variables
            if (variables) {
                _forIn(variables, (value, key) => {
                    document.documentElement.style.setProperty(
                        `--${key}`,
                        value
                    );
                    // generate RGB value
                    if (isValidHex(value)) {
                        document.documentElement.style.setProperty(
                            `--RGB_${key}`,
                            hexToRgb(value)
                        );
                    }
                });
            }
            // theme data
            storeDispatcher.setThemeData(components, constants);
        }
    }, []);

    useEffect(() => {
        const template = themeCommon.getTemplate(themeName);
        applyTheme(template);
    }, [themeName, applyTheme]);

    return null;
});

GlobalTheme.displayName = 'GlobalTheme';

export default GlobalTheme;
