import React, { memo } from 'react';
import { useSelector } from 'react-redux';

const GlobalComponent = memo(({ globalComponentName, ...props }) => {
    const SpecifiedComponent = useSelector(
        state => state.themeReducer.components[globalComponentName]
    );

    if (SpecifiedComponent) {
        return <SpecifiedComponent {...props} />;
    }
    return null;
});

GlobalComponent.displayName = 'GlobalComponent';

export default GlobalComponent;
