import { memo, PureComponent } from 'react';
import { getTranslatedText } from '../function';
import Context from './Context';

const withTranslation = () => WrappedComponent => {
    class TranslationHOC extends PureComponent {
        translate = (transKey, params) => {
            return getTranslatedText(transKey, params, this.props.resource);
        };

        render() {
            const { resource, changeLanguage, ...ownProps } = this.props;

            return (
                <WrappedComponent
                    translate={this.translate}
                    changeLanguage={changeLanguage}
                    {...ownProps}
                />
            );
        }
    }

    const TranslationWrapper = memo(props => {
        return (
            <Context.Consumer>
                {context => (
                    <TranslationHOC
                        {...props}
                        resource={context.resource}
                        changeLanguage={context.changeLanguage}
                    />
                )}
            </Context.Consumer>
        );
    });
    TranslationWrapper.displayName = 'TranslationWrapper';

    return TranslationWrapper;
};

export default withTranslation;
