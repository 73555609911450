import React, { useContext } from 'react';

export const RecipientLayoutContext = React.createContext({
    onLoadInfo: () => {},
    onChangeMenu: () => {},
    isHideMenu: false,
    infoData: null,
});

const useRecipientLayoutContext = () => useContext(RecipientLayoutContext);

export default useRecipientLayoutContext;
