import { memo } from 'react';
import { useSelector } from 'react-redux';

const GlobalConstantWrapper = memo(({ name, ...props }) => {
    const constantValue = useSelector(
        state => state.themeReducer.constants[name]
    );
    return props.children(constantValue);
});

GlobalConstantWrapper.displayName = 'GlobalConstantWrapper';

export default GlobalConstantWrapper;
