import { Menu } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { renderDonorCode } from 'view/DonorLayout/DeceasedDonorLayout/common';

const { SubMenu, Item } = Menu;

const LeftMenuPatient = ({
    menuList,
    isHideMenu,
    isDonor,
    donorInfo,
    titleId,
    titleName,
}) => {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(null);
    const [openKeys, setOpenKeys] = useState('');

    const getMenuList = useCallback(() => {
        let cloneMenuList = cloneDeep(menuList);
        let currentSubItem = '';

        if (!cloneMenuList.find(item => item.link === location.pathname)) {
            if (
                cloneMenuList.find(item =>
                    item.subItem?.find(item => item.link === location.pathname)
                )
            ) {
                currentSubItem = menuList.find(item => {
                    return item.subItem?.find(
                        item => item.link === location.pathname
                    );
                });
            }
        }

        setActiveMenu(cloneMenuList);
        setOpenKeys(currentSubItem.key);
    }, [location.pathname, menuList]);

    useEffect(() => {
        getMenuList();
    }, [getMenuList]);

    return (
        <div className={`menu-container ${isHideMenu ? 'hide' : ''}`}>
            <Menu
                className="menu"
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                openKeys={openKeys}
            >
                <div className="patientInfo">
                    {isDonor ? (
                        <div className="patientID">
                            {renderDonorCode(donorInfo)}
                        </div>
                    ) : (
                        <div className="patientID">{titleId || '--'}</div>
                    )}
                    <div className="patientName">{titleName}</div>
                </div>
                {activeMenu?.map(item =>
                    item.subItem ? (
                        <SubMenu key={item.key} title={item.title}>
                            {item.subItem.map(ele => (
                                <Item
                                    key={ele.link}
                                    className={
                                        ele.link === location.pathname
                                            ? 'item-active'
                                            : ''
                                    }
                                >
                                    <NavLink to={ele.link}>
                                        <span>{ele.title}</span>
                                    </NavLink>
                                </Item>
                            ))}
                        </SubMenu>
                    ) : (
                        <Item
                            key={item.key}
                            className={
                                item.link === location.pathname
                                    ? 'item-active'
                                    : ''
                            }
                        >
                            <NavLink to={item.link}>
                                <span>{item.title}</span>
                            </NavLink>
                        </Item>
                    )
                )}
            </Menu>
        </div>
    );
};

export default LeftMenuPatient;
