const ROUTE_RECIPIENT = '/recipient/:patientCode';

const routeConstant = {
    ROUTE_LIVING_DONOR: '/donor/:donorCode/living',
    ROUTE_LIVING_DONOR__ADMINISTRATIVE: '/donor/:donorCode/living/infor',
    ROUTE_LIVING_DONOR__ORGAN_LIST: '/donor/:donorCode/living/organlist',
    ROUTE_LIVING_DONOR__DIAGNOSIS: '/donor/:donorCode/living/diagnosis',
    ROUTE_LIVING_DONOR__HISTORY: '/donor/:donorCode/living/history',
    ROUTE_LIVING_DONOR__CLINICAL: '/donor/:donorCode/living/clinical',
    ROUTE_LIVING_DONOR__CHAMSOC_DIEUTRI:
        '/donor/:donorCode/living/cham-soc-dieu-tri',
    ROUTE_LIVING_DONOR__TREATMENT: '/donor/:donorCode/living/treatment',
    ROUTE_LIVING_DONOR__TEST_RESULTS: '/donor/:donorCode/living/test-result',
    ROUTE_LIVING_DONOR__MOLECULAR_BIOLOGY:
        '/donor/:donorCode/living/molecular-biology',
    ROUTE_LIVING_DONOR__HUYET_HOC_TRUYEN_MAU:
        '/donor/:donorCode/living/huyet_hoc_truyen_mau',
    ROUTE_LIVING_DONOR__HOA_SINH: '/donor/:donorCode/living/hoa_sinh',
    ROUTE_LIVING_DONOR__VI_SINH: '/donor/:donorCode/living/microbiology',
    ROUTE_LIVING_DONOR__PATHOLOGY: '/donor/:donorCode/living/pathology',
    ROUTE_LIVING_DONOR__HINH_ANH_HOC: '/donor/:donorCode/living/hinh-anh-hoc',
    ROUTE_LIVING_DONOR__CONCLUSION: '/donor/:donorCode/living/conclusion',
    ROUTE_LIVING_DONOR__CONCLUSION__ORGAN:
        '/donor/:donorCode/living/conclusion/organ/:organId',
    ROUTE_LIVING_DONOR__SURGERY: '/donor/:donorCode/living/surgery',

    ROUTE_DONOR__VIEW_PDF: '/donor-view-pdf/:donorCode',

    ROUTE_PASS_DONOR: '/donor/:donorCode/pass',
    ROUTE_PASS_DONOR__ADMINISTRATIVE:
        '/donor/:donorCode/pass/form/administrative',
    ROUTE_PASS_DONOR__FORM_LAYOUT: '/donor/:donorCode/pass/form/:formName',
    ROUTE_PASS_DONOR__ORGAN_LIST: '/donor/:donorCode/pass/organlist',
    ROUTE_PASS_DONOR__CONCLUSION: '/donor/:donorCode/pass/conclusion',
    ROUTE_PASS_DONOR__CONCLUSION__ORGAN:
        '/donor/:donorCode/pass/conclusion/organ/:organId',
    ROUTE_PASS_DONOR__SURGERY: '/donor/:donorCode/pass/surgery',

    ROUTE_RECIPIENT: ROUTE_RECIPIENT,
    ROUTE_RECIPIENT__ADMINISTRATIVE: `${ROUTE_RECIPIENT}/form/administrative`,
    ROUTE_RECIPIENT__FORM_LAYOUT: `${ROUTE_RECIPIENT}/form/:formName`,
    ROUTE_RECIPIENT__ORGAN_LIST: `${ROUTE_RECIPIENT}/organlist`,
    ROUTE_RECIPIENT__CONCLUSION: `${ROUTE_RECIPIENT}/conclusion`,
    ROUTE_RECIPIENT__CONCLUSION__ORGAN: `${ROUTE_RECIPIENT}/conclusion/organ/:organId`,
    ROUTE_RECIPIENT__SURGERY: `${ROUTE_RECIPIENT}/surgery`,
};

export const refactorRoute = {
    DONOR_REGISTER_LIST: '/donors/register',
    DONOR_WAITING_LIST: '/donors/waiting',
    DONOR_DONATED_LIST: '/donors/donated',
    DONOR_REJECTED_LIST: '/donors/rejected',
    RECEIVER_REGISTER_LIST: 'recipient/register',
    RECEIVER_WAITING_LIST: 'recipient/waiting',
    RECEIVER_RECEIVED_LIST: 'recipient/received',
    RECEIVER_REJECTED_LIST: 'recipient/rejected',
};

export default routeConstant;
