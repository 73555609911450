import { Action } from './Action';
import { storeFunc } from './Store';

const setError = errorCode => {
    storeFunc.dispatch({
        type: Action.SET_ERROR,
        errorCode: errorCode,
    });
};
const resetError = () => {
    storeFunc.dispatch({
        type: Action.SET_ERROR,
        errorCode: null,
    });
};

// set theme name
const setTheme = themeName => {
    storeFunc.dispatch({
        type: Action.SET_THEME,
        themeName,
    });
};

// set theme data
const setThemeData = (components, constants) => {
    storeFunc.dispatch({
        type: Action.SET_THEME_DATA,
        components,
        constants,
    });
};

// set user data
const setUserData = userProfile => {
    storeFunc.dispatch({
        type: Action.SET_USER_PROFILE,
        userProfile,
    });
};
// remove user data
const removeUserData = () => {
    storeFunc.dispatch({
        type: Action.SET_USER_PROFILE,
        userProfile: null,
    });
};
const getUserProfile = () => {
    return storeFunc.getState()?.commonReducer?.userProfile;
};

export const storeDispatcher = {
    setError,
    resetError,
    setTheme,
    setThemeData,
    setUserData,
    removeUserData,
    getUserProfile,
};
