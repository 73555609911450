import { memo, useCallback, useContext } from 'react';
import { getTranslatedText } from '../function';
import Context from './Context';

const TranslationWrapper = memo(props => {
    const { resource, changeLanguage } = useContext(Context);

    const translate = useCallback(
        (transKey, params) => {
            return getTranslatedText(transKey, params, resource);
        },
        [resource]
    );

    return props.children(translate, { changeLanguage });
});

TranslationWrapper.displayName = 'TranslationWrapper';

export default TranslationWrapper;
