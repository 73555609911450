export const ThemeConstant = {
    // component names
    MAIN_LOGO: 'MainLogo',
    STUDIO_LOGO: 'StudioLogo',
    COMPACT_LOGO: 'CompactLogo',

    // constant variabless
    PLACEHOLDER_IMAGE: 'placeholderImage',
    PLACEHOLDER_VIDEO: 'placeholderVideo',
    DEFAULT_AVATAR: 'defaultAvatar',

    DEFAULT_VIDEO_URL: 'defaultVideoUrl',
};
