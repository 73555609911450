import { Spin } from 'antd';
import { memo } from 'react';

const LoadingRoutes = memo(() => {
    return (
        <Spin
            size="large"
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        />
    );
});

LoadingRoutes.displayName = 'LoadingRoutes';

export default LoadingRoutes;
