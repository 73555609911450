export const organsOption = [
    {
        label: 'Tim',
        value: 'gz6Zn7DZ',
    },
    {
        label: 'Gan',
        value: 'jcWqL4fq',
    },
    {
        label: 'Thận',
        value: '9d2pTGrB',
    },
    {
        label: 'Phổi',
        value: 'YXx4JWMv',
    },
    {
        label: 'Tụy',
        value: '8ChfWfPS',
    },
    {
        label: 'Ruột non',
        value: 'ws4hBsvh',
    },
    {
        label: 'Giác mạc',
        value: 'J70svxnZ',
    },
    {
        label: 'Da',
        value: '2jpGXcrV',
    },
    {
        label: 'Xương',
        value: 'CPAKCHbm',
    },
    {
        label: 'Van tim',
        value: 'eYqYlTkR',
    },
    {
        label: 'Mạch máu',
        value: 'MxpyfznW',
    },
    {
        label: 'Chi thể',
        value: 'vUQVbaxX',
    },
    // {
    //    label: 'Lá lách',
    //    value: '2jpGXcrV',
    // },
    // {
    //    label: 'Mật',
    //    value: 'J70svxnZ',
    // },
];

export const reasonsOptions = [
    {
        label: 'Nhiễm khuẩn không khống chế được',
        value: 'nhiem_khuan_khong_khong',
    },
    {
        label: 'Viêm gan hoạt động',
        value: 'viem_gan_hoat_dong',
    },
    {
        label: 'Ung thư chưa khỏi',
        value: 'ung_thu_chua_khoi',
    },
    {
        label: 'Suy chức năng cơ quan',
        value: 'suy_chuc_nang_co_quan',
    },
    {
        label: 'Khác',
        value: 'khac',
    },
];

export const hinhAnhHocOptions = [
    {
        label: 'X-Quang tim phổi thẳng',
        value: 'x_quang_tim_phoi_thang',
    },
    {
        label: 'X-Quang hệ niệu không chuẩn bị',
        value: 'x_quang_he_nieu_khong_chuan_bi',
    },
    {
        label: 'Siêu âm bụng tổng quát',
        value: 'sieu_am_bung_tong_quat',
    },
    {
        label: 'Siêu âm hệ niệu',
        value: 'sieu_am_he_nieu',
    },
    {
        label: 'Siêu âm gan',
        value: 'Sieu_an_gan',
    },
    {
        label: 'Siêu âm Doppler tim',
        value: 'sieu_am_doppler_tim',
    },
    {
        label: 'Siêu âm Doppler khảo sát tưới máu gan',
        value: 'sieu_am_doppler_khao_sat_tuoi_mau_gan',
    },
    {
        label: 'Siêu âm Doppler khảo sát tưới máu thận',
        value: 'sieu_am_doppler_khao_sat_tuoi_mau_than',
    },
    {
        label: 'CT Scan bụng chậu',
        value: 'ct_scan_bung_chau',
    },
    {
        label: 'CT Scan sọ não',
        value: 'ct_scan_so_nao',
    },
    {
        label: 'EEG',
        value: 'eeg',
    },
    {
        label: 'ECG',
        value: 'ecg',
    },
    {
        label: 'Khác',
        value: 'khac',
    },
];

export const converStringToArray = string => {
    return (string && string.split(',')) || [];
};

export const returnMatchValue = (text1, text2, text3, text4) => {
    let array1 = converStringToArray(text1);
    let array2 = converStringToArray(text2);
    let array3 = converStringToArray(text3);
    let array4 = converStringToArray(text4);
    let hash = {};
    let result = [];
    for (let e of array1) {
        hash[e] = '1';
    }
    for (let e of array2) {
        hash[e] = '1';
    }
    for (let e of array3) {
        hash[e] = '1';
    }
    for (let f of array4) {
        if (hash[f]) {
            result.push(f);
        }
        // else {
        //    result.push('--')
        // }
    }
    return result;
};

export const returnMatchValueClass = (array1, array2) => {
    let hash = {};
    let result = [];
    for (let e of array1) {
        hash[e] = '1';
    }
    for (let f of array2) {
        if (hash[f]) {
            result.push(f);
        }
        // else {
        //    result.push('--')
        // }
    }
    return result;
};

export const nguyenNhanTuVong = [
    {
        value: 'tai_bien_mach_mau_nao',
        label: 'Tai biến mạch máu não',
    },
    {
        value: 'chan_thuong_so_nao',
        label: 'Chấn thương sọ não',
    },
    {
        value: 'u_he_than_kinh_trung_uong',
        label: 'U hệ thần kinh trung ương',
    },
    {
        value: 'thieu_oxy_mo',
        label: 'Thiếu oxy mô',
    },
    {
        value: 'khac',
        label: 'Khác',
    },
];

export const chanDoan = [
    {
        value: 'ngung_tim',
        label: 'Ngưng tim',
    },
    {
        value: 'chet_nao',
        label: 'Chết não',
    },
];
export const chuyenKhoa = [
    {
        value: 'kham_ngoai_tieu_hoa',
        label: 'Ngoại Tiêu hóa',
    },
    {
        value: 'kham_ngoai_than_kinh',
        label: 'Ngoại Thần kinh',
    },
    {
        value: 'kham_noi_than_kinh',
        label: 'Nội Thần kinh',
    },
    {
        value: 'kham_ngoai_long_nguc',
        label: 'Ngoại lồng ngực',
    },
    {
        value: 'kham_hoi_suc_ngoai_than_kinh',
        label: 'Hồi sức Ngoại thần kinh',
    },
    {
        value: 'cap_cuu',
        label: 'Cấp cứu',
    },
    {
        value: 'kham_hoi_suc_cap_cuu',
        label: 'Hồi sức cấp cứu',
    },
    {
        value: 'hoi_suc_tich_cuc',
        label: 'Hồi sức tích cực',
    },
    {
        value: 'chan_thuong_chinh_hinh',
        label: 'Chấn thương Chỉnh hình',
    },
    {
        value: 'phau_thuat_mach_mau',
        label: 'Phẫu thuật mạch máu',
    },
    {
        value: 'ngoai_gan_mat_tuy',
        label: 'Ngoại gan mật tụy',
    },
    {
        value: 'kham_mat',
        label: 'Mắt',
    },
    {
        value: 'kham_tai_mui_hong',
        label: 'Tai mũi họng',
    },
    {
        value: 'kham_noi_tim_mach',
        label: 'Nội tim mạch',
    },
    {
        value: 'kham_noi_phoi',
        label: 'Nội phổi',
    },
    {
        value: 'kham_noi_tieu_hoa',
        label: 'Nội tiêu hóa',
    },
    {
        value: 'benh_nhiet_doi',
        label: 'Bệnh nhiệt đới',
    },
    {
        value: 'kham_noi_than',
        label: 'Nội thận',
    },
    {
        value: 'khac',
        label: 'Khác',
    },
];

export const thuocSuDung = [
    {
        value: 'Adrenaline',
        label: 'Adrenaline',
    },
    {
        value: 'Noradrenalin',
        label: 'Noradrenalin',
    },
    {
        value: 'Dobutamine-Noradrenalin',
        label: 'Dobutamine- Noradrenalin',
    },
    {
        value: 'Dopamine',
        label: 'Dopamine',
    },
];

export const renderDonorCode = row => {
    // if (row?.donor_type === 'PASSED') {
    //    if (row?.donor_code && row?.classify) {
    //       return row?.classify + '_' + row?.donor_code
    //    }
    // }
    return row?.hospitalize_code || '--';
};

export const renderDonorOrgan = row => {
    if (row.approved_organs?.[0] === '' || !row.approved_organs) {
        return (row?.organs ?? []).join(', ') || '--';
    } else {
        // Map row.approved_organs to organsOption
        let approvedOrgans = row.approved_organs[0].split(',').map(organ => {
            let organOption = organsOption.find(
                option => option.value === organ
            );
            return organOption?.label || '--';
        });

        return approvedOrgans.join(', ') || '--';
    }
};

export const renderPatientCode = row => {
    return row?.benh_nhan_code || row?.nhap_vien_code || '--';
};

export const renderEPTSScore = {
    '0.03659817399191,0.25452908966461': 0,
    '0.25452908966461,0.42352944598300': 1,
    '0.42352944598300,0.53003080082136': 2,
    '0.53003080082136,0.62358042436687': 3,
    '0.62358042436687,0.71575920635007': 4,
    '0.71575920635007,0.79651301542106': 5,
    '0.79651301542106,0.86920864832432': 6,
    '0.86920864832432,0.93691375770021': 7,
    '0.93691375770021,1.00115259133800': 8,
    '1.00115259133800,1.0614750171115': 9,
    '1.06147501711157,1.11886399323084': 10,
    '1.11886399323084,1.17274132750756': 11,
    '1.17274132750756,1.22455411031063': 12,
    '1.22455411031063,1.27405270362765': 13,
    '1.27405270362765,1.32239366251146': 14,
    '1.32239366251146,1.36840427006353': 15,
    '1.36840427006353,1.41186789869952': 16,
    '1.41186789869952,1.45301619533377': 17,
    '1.45301619533377,1.49435112936345': 18,
    '1.49435112936345,1.53286770421574': 19,
    '1.53286770421574,1.57155441478439': 20,
    '1.57155441478439,1.60668450477357': 21,
    '1.60668450477357,1.64078781656400': 22,
    '1.64078781656400,1.67138347106792': 23,
    '1.67138347106792,1.70048254984577': 24,
    '1.70048254984577,1.72867145790554': 25,
    '1.72867145790554,1.75678302532512': 26,
    '1.75678302532512,1.78276238577397': 27,
    '1.78276238577397,1.80864887063655': 28,
    '1.80864887063655,1.83411430527036': 29,
    '1.83411430527036,1.85819479732733': 30,
    '1.85819479732733,1.88139546861610': 31,
    '1.88139546861610,1.90423427159151': 32,
    '1.90423427159151,1.92684448967469': 33,
    '1.92684448967469,1.95017274194208': 34,
    '1.95017274194208,1.97209769030884': 35,
    '1.97209769030884,1.99285503839760': 36,
    '1.99285503839760,2.01466894405460': 37,
    '2.01466894405460,2.03390212183436': 38,
    '2.03390212183436,2.05369746748802': 39,
    '2.05369746748802,2.07340246406571': 40,
    '2.07340246406571,2.09208772554548': 41,
    '2.09208772554548,2.11008008213552': 42,
    '2.11008008213552,2.12825462012320': 43,
    '2.12825462012320,2.14538566530003': 44,
    '2.14538566530003,2.16347433264887': 45,
    '2.16347433264887,2.17963031413852': 46,
    '2.17963031413852,2.19604187305641': 47,
    '2.19604187305641,2.21302327173169': 48,
    '2.21302327173169,2.22890885657669': 49,
    '2.22890885657669,2.24521560574949': 50,
    '2.24521560574949,2.26099514620549': 51,
    '2.26099514620549,2.27693714028400': 52,
    '2.27693714028400,2.29328883840429': 53,
    '2.29328883840429,2.30873374401095': 54,
    '2.30873374401095,2.32508761122519': 55,
    '2.32508761122519,2.34046399709776': 56,
    '2.34046399709776,2.35469883641342': 57,
    '2.35469883641342,2.36976221190132': 58,
    '2.36976221190132,2.38548117727584': 59,
    '2.38548117727584,2.40080210992761': 60,
    '2.40080210992761,2.41601323013786': 61,
    '2.41601323013786,2.43071022841615': 62,
    '2.43071022841615,2.44635936535286': 63,
    '2.44635936535286,2.46081562059785': 64,
    '2.46081562059785,2.47507281008807': 65,
    '2.47507281008807,2.49097050616980': 66,
    '2.49097050616980,2.50655989048597': 67,
    '2.50655989048597,2.52244591190598': 68,
    '2.52244591190598,2.53838298001874': 69,
    '2.53838298001874,2.55365614791238': 70,
    '2.55365614791238,2.56946475017112': 71,
    '2.56946475017112,2.58636928647468': 72,
    '2.58636928647468,2.60244038836947': 73,
    '2.60244038836947,2.61972240525357': 74,
    '2.61972240525357,2.63526078028747': 75,
    '2.63526078028747,2.65245419737158': 76,
    '2.65245419737158,2.67072065357604': 77,
    '2.67072065357604,2.68757487707999': 78,
    '2.68757487707999,2.70595570009828': 79,
    '2.70595570009828,2.72401095140315': 80,
    '2.72401095140315,2.74286793947642': 81,
    '2.74286793947642,2.76238315880849': 82,
    '2.76238315880849,2.78086498100229': 83,
    '2.78086498100229,2.80019769964201': 84,
    '2.80019769964201,2.82065812497000': 85,
    '2.82065812497000,2.84154403217207': 86,
    '2.84154403217207,2.86302665309162': 87,
    '2.86302665309162,2.88448137367205': 88,
    '2.88448137367205,2.90710010789073': 89,
    '2.90710010789073,2.93088554597975': 90,
    '2.93088554597975,2.95633172813984': 91,
    '2.95633172813984,2.98243616034011': 92,
    '2.98243616034011,3.01100193655189': 93,
    '3.01100193655189,3.04363481752507': 94,
    '3.04363481752507,3.07945530651501': 95,
    '3.07945530651501,3.11944421677048': 96,
    '3.11944421677048,3.17254475204181': 97,
    '3.17254475204181,3.25150892624088': 98,
    '3.25150892624088,3.25150892624089': 99,
};

export const renderEPTS = text => {
    if (text) {
        let result = text;
        if (text > 3.25150892624088) {
            result = 100;
        } else if (text >= 0 && text < 0.03659817399191) {
            result = 0;
        } else if (text < 0) {
            result = 0;
        } else {
            for (let [key, value] of Object.entries(renderEPTSScore)) {
                let points = key.split(',');
                if (points && points.length !== 0) {
                    if (text >= Number(points[0]) && text < Number(points[1])) {
                        result = value;
                        break;
                    }
                }
            }
        }
        return result;
    } else {
        return '--';
    }
};
