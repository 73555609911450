import { memo } from 'react';
import { useSelector } from 'react-redux';

const GlobalConstant = memo(({ globalConstantName }) => {
    const constantValue = useSelector(
        state => state.themeReducer.constants[globalConstantName]
    );

    return constantValue ?? null;
});

GlobalConstant.displayName = 'GlobalConstant';

export default GlobalConstant;
