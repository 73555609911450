import Loadable from 'react-loadable';
import { ThemeConstant } from '../../enums/ThemeConstant';

// template css variables
const variables = {
    /**
     * color
     */
    'color-text-1': '#000000',
    'color-text-2': '#696969',
    'color-text-3': '#A6A6A6',
    'color-disable': '#aaa9b7',

    'color-light': '#ffffff',
    'color-pink': '#EB236B',
    'color-purple': '#8A56BE',

    'color-background': '#f5f5f5',
    'color-gray-line': '#dbdbdb',
    'color-gray-line-light': '#ebebeb',
    'color-gray-medium': '#65627A',
    'color-black-5': '--black-5, rgba(0, 0, 0, 0.05)',

    'color-primary': '#394BC7',
    'color-primary-light': '#714fff',
    'color-primary-dark': '#2B3DB4',
    'color-primary-hover': '#453396',
    'color-primary-background': '#f2f0ff',
    'color-primary-logout': '#ff6646',

    'color-progress': '#ff885c',
    'color-progress-dark': '#fe7745',
    'color-secondary-hover': '#ff7c4b',

    'color-danger': '#ff5f73',
    'color-danger-dark': '#f35367',

    'color-success-default-hover': '#6da23a',
    'color-success-default': '#81b351',

    'color-silver-foil': '#afafaf',
    'color-bright-gray': '#efefef',
    'color-light-gray': '#d3d3d3',
    'color-gray': '#d8d8d8',
    'color-blue-header': '#b1e3ff',
    'color-green-header': '#c1efb1',
    'color-button': '#6da13a',
    'color-light-blue': '#428bff',
    /**
     * shadow
     */
    'box-shadow-small': '0px 0.3px 2px rgba(0, 0, 0, 0.3)',
    'box-shadow-medium':
        '0px 2px 8px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.15)',
    'box-shadow-big':
        '0px 4px 12px rgba(0, 0, 0, 0.15), 0px 6px 24px rgba(0, 0, 0, 0.2)',
    'box-shadow-light': '0px 0px 2px rgba(0, 0, 0, 0.2)',
    'inner-shadow': 'inset 0px 0px 2px 0.5px rgba(0, 0, 0, 0.1)',
    'inner-shadow-line-bot': 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
    'inner-shadow-line-top': 'inset 0px 1px 0px rgba(0, 0, 0, 0.08)',

    /**
     * border radius
     */
    'border-radius-h64': '16px',
    'border-radius-h48': '8px',
    'border-radius-h40': '8px',

    /**
     * font
     */
    'font-regular':
        '"Inter-regular", "Roboto", "Arial", "Helvetica", sans-serif',
    'font-semibold':
        '"Inter-semibold", "Inter-regular", "Roboto", "Arial", "Helvetica", sans-serif',
    'font-bold':
        '"Inter-bold", "Inter-regular", "Roboto", "Arial", "Helvetica", sans-serif',
    'font-light':
        '"Inter-light", "Inter-regular", "Roboto", "Arial", "Helvetica", sans-serif',
    'font-medium':
        '"Inter-medium", "Inter-regular", "Roboto", "Arial", "Helvetica", sans-serif',
    'font-icon': '"elite-icon-font"',
    'font-certificate-heading':
        '"UTM-Roman-Classic", "Inter-semibold", "Roboto", "Arial", "Helvetica", sans-serif',
    'font-certificate-label':
        '"SnellRoundhand", "Inter-semibold", "Roboto", "Arial", "Helvetica", sans-serif',
};

const MainLogo = Loadable({
    loader: () => import('./components/MainLogo'),
    loading: () => null,
});
const StudioLogo = Loadable({
    loader: () => import('./components/StudioLogo'),
    loading: () => null,
});
const CompactLogo = Loadable({
    loader: () => import('./components/CompactLogo'),
    loading: () => null,
});

// template components
const components = {
    [ThemeConstant.MAIN_LOGO]: MainLogo,
    [ThemeConstant.STUDIO_LOGO]: StudioLogo,
    [ThemeConstant.COMPACT_LOGO]: CompactLogo,
};

// template constants
const constants = {
    [ThemeConstant.PLACEHOLDER_IMAGE]: require('./assets/img-placeholder.svg'),
    [ThemeConstant.PLACEHOLDER_VIDEO]: require('./assets/video-placeholder.svg'),
    [ThemeConstant.DEFAULT_AVATAR]: require('./assets/default-avatar.svg'),

    [ThemeConstant.DEFAULT_VIDEO_URL]: 'youtube.com/watch?v=ES52MqSxBik',
};

export default {
    variables,
    components,
    constants,
};
