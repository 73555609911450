import { Action } from '../Action';
const initialState = {
    themeName: '',
    components: {},
    constants: {},
};

export const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case Action.SET_THEME:
            return { ...state, themeName: action.themeName };
        case Action.SET_THEME_DATA:
            return {
                ...state,
                components: action.components || {},
                constants: action.constants || {},
            };
        default:
            return state;
    }
};
