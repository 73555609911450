import React from 'react';
import _mergeWith from 'lodash/mergeWith';
import _isObject from 'lodash/isObject';
import _isFunction from 'lodash/isFunction';
import templateMain from './templates/main';
import GlobalComponent from './components/GlobalComponent';
import GlobalConstant from './components/GlobalConstant';
import { storeFunc } from '../store/Store';

const _mergeTemplate = (obj1, obj2) => {
    return _mergeWith({}, obj1, obj2, (o, s) => {
        if (_isObject(s) && !_isFunction(s)) {
            return _mergeTemplate(o, s);
        }
        return !s ? o : s;
    });
};

// get template
const getTemplate = themeName => {
    let template = null;

    // switch template
    if (themeName === 'dark') {
        // temporary - testing
        template = require('./templates/dark').default;
    }

    if (!template) {
        template = templateMain;
    } else {
        template = _mergeTemplate(templateMain, template);
    }
    console.log(template);
    return template;
};

// switch theme
const switchTheme = themeName => {
    const { storeDispatcher } = require('../store/storeDispatcher');
    storeDispatcher.setTheme(themeName);
};

// GlobalComponent - used to RENDER specified theme component.
const useGlobalComponent = globalComponentName => props => {
    return (
        <GlobalComponent globalComponentName={globalComponentName} {...props} />
    );
};

// RENDER global constant: used to RENDER specified theme constant.
const renderGlobalConstant = globalConstantName => {
    return <GlobalConstant globalConstantName={globalConstantName} />;
};

// GET global constant: used to get only specified theme constant.
const getGlobalConstant = globalConstantName => {
    return (
        storeFunc.getState().themeReducer.constants[globalConstantName] ?? null
    );
};

export const themeCommon = {
    getTemplate,
    switchTheme,
    useGlobalComponent,
    renderGlobalConstant,
    getGlobalConstant,
};

/**
 * Wrapped component with Global constant.
 */
export { default as GlobalConstantWrapper } from './components/GlobalConstantWrapper';
/**
 * HOCs with Global constant.
 */
export { default as useGlobalConstant } from './components/useGlobalConstant';
export { default as withGlobalConstant } from './components/withGlobalConstant';
