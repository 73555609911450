import { generatePath, useParams } from 'react-router-dom';
import routeConstant from 'route/routeConstant';
import { translate } from 'translation';
import LeftMenuPatient from 'utils/Components/LeftMenuPatient';

const LeftMenuDonor = props => {
    const { donorCode } = useParams();
    const menuList = [
        {
            key: 'administrative',
            title: translate('leftmenu.administrative'),

            link: generatePath(
                routeConstant.ROUTE_LIVING_DONOR__ADMINISTRATIVE,
                {
                    donorCode,
                }
            ),
        },
        {
            key: 'diagnosis',
            title: translate('leftmenu.diagnosis'),
            link: generatePath(routeConstant.ROUTE_LIVING_DONOR__DIAGNOSIS, {
                donorCode,
            }),
        },
        {
            key: 'tiencan',
            title: translate('leftmenu.history'),
            link: generatePath(routeConstant.ROUTE_LIVING_DONOR__HISTORY, {
                donorCode,
            }),
        },
        {
            key: 'clinical',
            title: 'Diễn tiến lâm sàng',
            link: generatePath(routeConstant.ROUTE_LIVING_DONOR__CLINICAL, {
                donorCode,
            }),
        },
        {
            key: 'test-results',
            title: translate('leftmenu.test_results'),
            subItem: [
                {
                    key: 'molecularBiology',
                    title: translate('leftmenu.molecular_biology'),
                    link: generatePath(
                        routeConstant.ROUTE_LIVING_DONOR__MOLECULAR_BIOLOGY,
                        {
                            donorCode,
                        }
                    ),
                },
                {
                    key: 'huyet_hoc_truyen_mau',
                    title: translate('leftmenu.huyet_hoc_truyen_mau'),
                    link: generatePath(
                        routeConstant.ROUTE_LIVING_DONOR__HUYET_HOC_TRUYEN_MAU,
                        {
                            donorCode,
                        }
                    ),
                },
                {
                    key: 'hoa_sinh',
                    title: translate('leftmenu.hoa_sinh'),
                    link: generatePath(
                        routeConstant.ROUTE_LIVING_DONOR__HOA_SINH,
                        {
                            donorCode,
                        }
                    ),
                },
                {
                    key: 'microbiology',
                    title: translate('leftmenu.microbiology'),
                    link: generatePath(
                        routeConstant.ROUTE_LIVING_DONOR__VI_SINH,
                        {
                            donorCode,
                        }
                    ),
                },
                {
                    key: 'pathology',
                    title: translate('leftmenu.pathology'),
                    link: generatePath(
                        routeConstant.ROUTE_LIVING_DONOR__PATHOLOGY,
                        {
                            donorCode,
                        }
                    ),
                },
            ],
        },
        {
            key: 'hinh-anh-hoc',
            title: 'Hình ảnh học',
            link: generatePath(routeConstant.ROUTE_LIVING_DONOR__HINH_ANH_HOC, {
                donorCode,
            }),
        },
        {
            key: 'conclusion',
            title: 'Kết luận',

            link: generatePath(routeConstant.ROUTE_LIVING_DONOR__CONCLUSION, {
                donorCode,
            }),
        },
        {
            key: 'surgery',
            title: 'Thông tin về cuộc mổ',
            link: generatePath(routeConstant.ROUTE_LIVING_DONOR__SURGERY, {
                donorCode,
            }),
        },
    ];

    return (
        <LeftMenuPatient
            titleName={props.infoData?.full_name}
            titleId={props.infoData?.donor_code}
            menuList={menuList}
            isHideMenu={props.isHideMenu}
        />
    );
};
export default LeftMenuDonor;
