import { commonReducer, errorReducer, themeReducer } from './Reducer';

const redux = require('redux');

const appReducer = redux.combineReducers({
    commonReducer: commonReducer,
    errorReducer: errorReducer,
    themeReducer: themeReducer,
});

const store = redux.createStore(appReducer);

store.subscribe(() => {
    // console.log(store.getState());
});

const getState = () => {
    return store.getState();
};

export default store;
export const storeFunc = {
    getState,
    dispatch: store.dispatch,
};
