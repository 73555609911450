export const FormName = {
    recipient__thong_tin_hanh_chinh: 'recipient__thong_tin_hanh_chinh',
    recipient__chan_doan: 'recipient__chan_doan',
    recipient__benh_than: 'recipient__benh_than',
    recipient__kham_lam_sang: 'recipient__kham_lam_sang',
    recipient__vi_khuan: 'recipient__vi_khuan',
    recipient__lao: 'recipient__lao',
    recipient__viem_gan_svi: 'recipient__viem_gan_svi',
    recipient__ebv: 'recipient__ebv',
    recipient__cmv: 'recipient__cmv',
    recipient__dau_an_ung_thu: 'recipient__dau_an_ung_thu',
    recipient__dong_hoc_men_tim: 'recipient__dong_hoc_men_tim',
    recipient__hiv: 'recipient__hiv',
    recipient__giang_mai: 'recipient__giang_mai',
    recipient__virus_khac: 'recipient__virus_khac',
    recipient__phan_tich_te_bao: 'recipient__phan_tich_te_bao',
    recipient__giai_phau_benh: 'recipient__giai_phau_benh',

    recipient__ket_luan: 'recipient__ket_luan',
    recipient__cap_nhat_ket_luan: 'recipient__cap_nhat_ket_luan',
    recipient__sinh_hoat_va_benh_ly_khac:
        'recipient__sinh_hoat_va_benh_ly_khac',
    recipient__sinh_hoc_phan_tu_chung: 'recipient__sinh_hoc_phan_tu_chung',
    recipient__sinh_hoc_phan_tu: 'recipient__sinh_hoc_phan_tu',
    recipient__mien_dich_bilan_tma: 'recipient__mien_dich_bilan_tma',
    recipient__mien_dich_bilan_lupus: 'recipient__mien_dich_bilan_lupus',
    recipient__mien_dich_bilan_viem_gan_tu_mien:
        'recipient__mien_dich_bilan_viem_gan_tu_mien',
    recipient__hinh_anh_hoc: 'recipient__hinh_anh_hoc',
    recipient__dong_mau: 'recipient__dong_mau',
    recipient__sinh_hoa_huyet_hoc: 'recipient__sinh_hoa_huyet_hoc',
    recipient__ghi_chu: 'recipient__ghi_chu',
    recipient__dich_mang_bung: 'recipient__dich_mang_bung',
    recipient__nuoc_tieu: 'recipient__nuoc_tieu',
    recipient__duong_huyet: 'recipient__duong_huyet',
    recipient__bilan_lipid: 'recipient__bilan_lipid',
    recipient__chuc_nang_gan: 'recipient__chuc_nang_gan',
    recipient__chuc_nang_than: 'recipient__chuc_nang_than',
    recipient__dien_giai_do: 'recipient__dien_giai_do',
    recipient__bilan_nhiem_trung: 'recipient__bilan_nhiem_trung',
    recipient__khac: 'recipient__khac',
    recipient__nhom_mau: 'recipient__nhom_mau',
    recipient__chuc_nang_tuyen_giap: 'recipient__chuc_nang_tuyen_giap',
    recipient__vi_sinh_lao: 'recipient__vi_sinh_lao',
    recipient__vi_sinh_khang_sinh: 'recipient__vi_sinh_khang_sinh',
    recipient__vi_sinh_ky_sinh_trung: 'recipient__vi_sinh_ky_sinh_trung',
    recipient__vi_sinh_nuoc_tieu: 'recipient__vi_sinh_nuoc_tieu',
    recipient__vi_sinh_dam: 'recipient__vi_sinh_dam',
    recipient__vi_sinh_dich: 'recipient__vi_sinh_dich',
    recipient__vi_sinh_phan: 'recipient__vi_sinh_phan',
    recipient__dien_di_hb: 'recipient__dien_di_hb',
    recipient__alpha_thalassemia: 'recipient__alpha_thalassemia',
    recipient__beta_thalassemia: 'recipient__beta_thalassemia',
    recipient__bilan_sat: 'recipient__bilan_sat',
    recipient__dien_di_protein: 'recipient__dien_di_protein',
    recipient__sinh_hoa_khac: 'recipient__sinh_hoa_khac',
    recipient__can_lam_sang_mien_dich_xet_nghiem_khac:
        'recipient__can_lam_sang_mien_dich_xet_nghiem_khac',
    recipient__can_lam_sang_huyet_hoc_xet_nghiem_khac:
        'recipient__can_lam_sang_huyet_hoc_xet_nghiem_khac',
    recipient__can_lam_sang_sinh_hoa_xet_nghiem_khac:
        'recipient__can_lam_sang_sinh_hoa_xet_nghiem_khac',
    recipient__can_lam_sang_vi_sinh_xet_nghiem_khac:
        'recipient__can_lam_sang_vi_sinh_xet_nghiem_khac',

    deceased_donor__tien_can_gia_dinh: 'deceased_donor__tien_can_gia_dinh',
    deceased_donor__tien_can_tinh_duc: 'deceased_donor__tien_can_tinh_duc',
    deceased_donor__tien_can_ban_than: 'deceased_donor__tien_can_ban_than',
    deceased_donor__ket_luan: 'deceased_donor__ket_luan',
    deceased_donor__cap_nhat_ket_luan: 'deceased_donor__cap_nhat_ket_luan',
    deceased_donor__sinh_hoc_phan_tu: 'deceased_donor__sinh_hoc_phan_tu',
    deceased_donor__thong_tin_cuoc_mo: 'deceased_donor__thong_tin_cuoc_mo',
    deceased_donor__huyet_hoc_mau: 'deceased_donor__huyet_hoc_mau',
    deceased_donor__huyet_hoc_dich_tuy_nao:
        'deceased_donor__huyet_hoc_dich_tuy_nao',
    deceased_donor__huyet_hoc_dong_mau: 'deceased_donor__huyet_hoc_dong_mau',
    deceased_donor__hoa_sinh_mau: 'deceased_donor__hoa_sinh_mau',
    deceased_donor__hinh_anh_hoc: 'deceased_donor__hinh_anh_hoc',
    deceased_donor__chan_doan: 'deceased_donor__chan_doan',
    deceased_donor__chan_doan_chung: 'deceased_donor__chan_doan_chung',
    deceased_donor__dien_tien_lam_sang: 'deceased_donor__dien_tien_lam_sang',
    deceased_donor__kham_lam_sang: 'deceased_donor__kham_lam_sang',
    deceased_donor__huyet_thanh: 'deceased_donor__huyet_thanh',
    deceased_donor__nuoi_cay: 'deceased_donor__nuoi_cay',
    deceased_donor__nhuom_gram: 'deceased_donor__nhuom_gram',
    deceased_donor__sinh_hoa_nuoc_tieu: 'deceased_donor__sinh_hoa_nuoc_tieu',
    deceased_donor__sinh_hoa_dich_nao_tuy:
        'deceased_donor__sinh_hoa_dich_nao_tuy',
    deceased_donor__huyet_hoc_nhom_mau: 'deceased_donor__huyet_hoc_nhom_mau',
    deceased_donor__huyet_hoc_nuoc_tieu: 'deceased_donor__huyet_hoc_nuoc_tieu',
    deceased_donor__cham_soc_noi_khi_quan:
        'deceased_donor__cham_soc_noi_khi_quan',
    deceased_donor__cham_soc_ecmo: 'deceased_donor__cham_soc_ecmo',
    deceased_donor__cham_soc_khi_mau_dong_mach:
        'deceased_donor__cham_soc_khi_mau_dong_mach',
    deceased_donor__cham_soc_khi_mau_tinh_mach:
        'deceased_donor__cham_soc_khi_mau_tinh_mach',
    deceased_donor__cham_soc_ngung_tim: 'deceased_donor__cham_soc_ngung_tim',
    deceased_donor__cham_soc_thuoc_van_mach:
        'deceased_donor__cham_soc_thuoc_van_mach',
    deceased_donor__cham_soc_nhiem_khuan:
        'deceased_donor__cham_soc_nhiem_khuan',
    deceased_donor__huyet_hoc_truyen_mau_xet_nghiem_khac:
        'deceased_donor__huyet_hoc_truyen_mau_xet_nghiem_khac',

    living_donor__tien_can_ban_than: 'living_donor__tien_can_ban_than',
    living_donor__tien_can_gia_dinh: 'living_donor__tien_can_gia_dinh',
    living_donor__tien_can_sinh_hoat_tinh_duc:
        'living_donor__tien_can_sinh_hoat_tinh_duc',
};
