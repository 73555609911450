import { Action } from '../Action';
const initialState = {
    userProfile: null,
};

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case Action.SET_USER_PROFILE:
            if (action.userProfile !== state.userProfile) {
                return {
                    ...state,
                    userProfile: action.userProfile,
                };
            }
            return state;
        default:
            return state;
    }
};
