import { HashRouter, Route, Routes } from 'react-router-dom';
import GlobalTheme from './themes/components/GlobalTheme';
import routes from 'route/routes';

const renderRoute = route => {
    return (
        <Route
            key-={route.name}
            path={route.path}
            element={<route.component />}
        >
            {route.children?.map(renderRoute)}
        </Route>
    );
};

function App() {
    return (
        <>
            <GlobalTheme />
            <HashRouter>
                <Routes>{routes.map(renderRoute)}</Routes>
            </HashRouter>
        </>
    );
}
export default App;
