import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import useRecipientLayoutContext from 'view/RecipientLayout/RecipientLayoutContext';
import LeftMenuDonor from './LeftMenuDonor';

const DeceasedDonorMain = memo(() => {
    const { isHideMenu, infoData } = useRecipientLayoutContext();
    return (
        <div className="home-container">
            <LeftMenuDonor infoData={infoData} isHideMenu={isHideMenu} />
            <div
                className={`patient-main-container ${isHideMenu ? 'open' : ''}`}
            >
                <Outlet />
            </div>
        </div>
    );
});

DeceasedDonorMain.displayName = 'DeceasedDonorMain';

export default DeceasedDonorMain;
