import common_en from './en/common.json';
import common_vi from './vi/common.json';

const COOKIE_LANGUAGE_NAME = 'elitelearning-language-preference';
const defaultLanguage = 'vi';
const defaultNamespace = 'common';

const resources = {
    vi: {
        common: common_vi,
    },
    en: {
        common: common_en,
    },
};

const config = {
    defaultLanguage,
    defaultNamespace,
    resources,
};

export { COOKIE_LANGUAGE_NAME, config };
