import { memo, useContext, useMemo } from 'react';
import Context from './Context';
import { getTranslatedText } from '../function';

const Translation = memo(({ transKey, params }) => {
    const { resource } = useContext(Context);

    const transText = useMemo(() => {
        return getTranslatedText(transKey, params, resource);
    }, [transKey, params, resource]);

    return transText;
});

Translation.displayName = 'Translation';

export default Translation;
