import Loadable from 'react-loadable';
import { ThemeConstant } from '../../enums/ThemeConstant';

// template css variables
const variables = {
    /**
     * color
     */
    'color-text-1': 'white',
    'color-text-2': '#65627a',
    'color-disable': 'black',
};

const MainLogo = Loadable({
    loader: () => import('./components/MainLogo'),
    loading: () => null,
});

// template components
const components = {
    [ThemeConstant.MAIN_LOGO]: MainLogo,
};

// template constants
const constants = {
    [ThemeConstant.PLACEHOLDER_IMAGE]: require('./assets/img-placeholder.svg'),
};

export default {
    variables,
    components,
    constants,
};
