import Translation from './components/Translation';

/**
 * render translated text
 * @param {String} transKey
 * @param {*} params
 * @returns
 */
const translate = (transKey, params) => {
    return <Translation transKey={transKey} params={params} />;
};

export { translate };

/**
 * Wrap App.js with translation Provider
 */
export { default as Localization } from './components/Localization';
/**
 * Hook used for functional component
 */
export { default as useTranslation } from './components/useTranslation';
/**
 * HOC used for class component
 */
export { default as withTranslation } from './components/withTranslation';
/**
 * Wrap component with translation props
 */
export { default as TranslationWrapper } from './components/TranslationWrapper';
