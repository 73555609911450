import { Menu } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { memo, useMemo } from 'react';
import { NavLink, generatePath, useParams } from 'react-router-dom';
import routeConstant from 'route/routeConstant';
import { renderDonorCode } from 'view/DonorLayout/DeceasedDonorLayout/common';

const { SubMenu, Item } = Menu;

const Sidebar = memo(({ menuList, isHideMenu, donorInfo }) => {
    const { formName, donorCode } = useParams();

    const processMenuList = useMemo(() => {
        const cloneMenuList = cloneDeep(menuList);

        return cloneMenuList.map(item => {
            if (item.tabs) {
                return {
                    title: item.title,
                    key: item.key,
                    link: generatePath(
                        routeConstant.ROUTE_PASS_DONOR__FORM_LAYOUT,
                        {
                            donorCode,
                            formName: item.tabs[0],
                        }
                    ),
                    active: item.tabs.includes(formName),
                };
            }

            if (item.subItems) {
                return {
                    title: item.title,
                    key: item.key,
                    subItems: item.subItems.map(element => {
                        return {
                            key: element.key,
                            title: element.title,
                            link:
                                element.link ||
                                generatePath(
                                    routeConstant.ROUTE_PASS_DONOR__FORM_LAYOUT,
                                    {
                                        donorCode,
                                        formName: element.tabs?.[0],
                                    }
                                ),
                            active: element.tabs?.includes(formName),
                        };
                    }),
                    tabs: item.subItems.map(element => element.tabs).flat(),
                    active: item.subItems.some(element =>
                        element.tabs.includes(formName)
                    ),
                };
            }

            return item;
        });
    }, [donorCode, formName, menuList]);

    const openKeys = useMemo(() => {
        return processMenuList.find(item => item?.tabs?.includes(formName))
            ?.key;
    }, [formName, processMenuList]);

    const defaultSelectedKeys = useMemo(() => {
        return processMenuList
            .find(item => item?.tabs?.includes(formName))
            ?.subItems?.find(item => item?.tabs?.includes(formName))?.key;
    }, [formName, processMenuList]);

    return (
        <div className={`menu-container ${isHideMenu ? 'hide' : ''}`}>
            <Menu
                className="menu"
                mode="inline"
                defaultSelectedKeys={defaultSelectedKeys}
                openKeys={openKeys}
            >
                <div className="patientInfo">
                    <div className="patientID">
                        {renderDonorCode(donorInfo)}
                    </div>
                    <div className="patientName">{donorInfo.full_name}</div>
                </div>
                {processMenuList?.map(item =>
                    item.subItems ? (
                        <SubMenu key={item.key} title={item.title}>
                            {item.subItems.map(ele => (
                                <Item
                                    key={ele.link}
                                    className={ele.active ? 'item-active' : ''}
                                >
                                    <NavLink to={ele.link}>{ele.title}</NavLink>
                                </Item>
                            ))}
                        </SubMenu>
                    ) : (
                        <Item
                            key={item.key}
                            className={item.active ? 'item-active' : ''}
                        >
                            <NavLink to={item.link}>{item.title}</NavLink>
                        </Item>
                    )
                )}
            </Menu>
        </div>
    );
});

Sidebar.displayName = 'Sidebar';

export default Sidebar;
